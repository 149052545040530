<template>
  <div>
      <p class="font-bold text-2xl">Jadwal Minum</p>
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="col-5 p-2" style="font-size:12px;">
          <div class="shadow p-3 rounded-lg bg-white">
          <!-- INPUT -->
          <div class="sm-form">
            <input
              type="text"
              id="id"
              name="id"
              class="form-control form-control-sm"
              placeholder="id"
              v-model="vdata.id"
              disabled
            />
          </div>
          <div class="table-responsive">
            <table class="table table-sm table-bordered table-striped"> 
                 <tr>        
                    <td class="capitalize">Pasien</td>
                    <td class="pl-2 pr-2">:</td>
                    <td>
                        <v-select :options="pelanggans" label="nama" v-model="vdata.pelanggan" :reduce="e => e.id"></v-select>    
                        <button type="button" v-if="vdata.pelanggan" @click="$router.push(`/admin/pelanggan/jadwal_minum?id=${this.vdata.pelanggan}`)" class="btn btn-sm btn-primary  ">Cek Jadwal Minum</button>
                    </td>
                </tr>
                 <tr>        
                    <td class="capitalize">Tanggal Minum</td>
                    <td class="pl-2 pr-2">:</td>
                    <td>
                        <div class="sm-form ">
                            <input type="date" id="tanggal_minum" name="tanggal_minum" class="form-control form-control-sm" placeholder="tanggal_minum" v-model="vdata.tanggal_minum" >
                        </div>
                    </td>
                </tr>
                 <tr>        
                    <td class="capitalize">Jam Minum</td>
                    <td class="pl-2 pr-2">:</td>
                    <td>
                        <div class="sm-form ">
                            <input type="time" id="jam_minum" name="jam_minum" class="form-control form-control-sm" placeholder="jam_minum" v-model="vdata.jam_minum" >
                        </div>
                    </td>
                </tr>
            </table>
          </div>
          <!-- END INPUT -->
          <!-- BUTTON PART -->
          <div class="mt-3 p-3">
            <button
              type="submit"
              class="btn btn-sm"
              :class="
                btn == 'tambah'
                  ? 'btn-primary'
                  : btn == 'update'
                  ? 'btn-success'
                  : 'btn-danger'
              "
            >
              <span class="typcn typcn-info"></span> Proses  {{ btn }}
            </button>
            <div class="float-right">
              <button
                type="button"
                v-show="btn!='tambah'"
                @click="
                  btn = 'tambah';
                  vdata = {};
                "
                class="btn btn-sm btn-dark"
              >
                <span class="typcn typcn-edit"></span> Tambah
              </button>
              <button
                type="button"
                @click="btn = 'delete'"
                v-show="btn!='tambah'"
                class="ml-3 btn btn-sm btn-danger"
              >
                <span class="typcn typcn-delete"></span> Delete
              </button>
            </div>
          </div>
          </div>
          <!-- END BUTTON PART -->
        </div>
        <div class="col-7 p-2" style="font-size:12px;">
          <div class="shadow p-3 rounded-lg bg-white">
            <!-- TABLE PART -->
            <div v-if="datanya.length>0" class="p-3">
                <Btables :tables="datanya"  :cari="['']" :hide="['']" @selected="ambil" ref="btable" />
            </div>
            <!-- END TABLE PART -->
         </div>
        </div>
      </div>
    </form>
     <div class="d-block d-sm-none"><br><br><br><br></div>

  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
export default {
  components: {
    Btables,
  },
  layout:'app_shop_admin',
  data() {
    return {
      selected: false,
      vdata: {},
      btn: "tambah",
      datanya:[],
      pelanggans:[],
    };
  },
  methods: {
    app_kasir_pelanggan_minum(){
      this.$refs.btable.getData()
    },
    getDataPelanggan(){
        //doc adalah select *
        sdb.collection("app_kasir_pelanggan").doc().get("*","order by id").then(res => {
            this.pelanggans=res;
            this.$forceUpdate();
        });
        
    },
    getData(){
      sdb.collection("app_kasir_pelanggan_minum",false).doc().select(`select * from app_kasir_pelanggan_minum`).then(res => {
        this.datanya=res;
        this.$forceUpdate();
      });
      
    },
    ambil(data) {
      this.vdata = data;
      this.btn = "update";
      this.$forceUpdate();
    },
     format(tgl) {
          return this.$datefns.format(
            new Date(tgl),
            "YYYY-MM-DD"
          );
        },
    submit() {
      let that=this
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          //idnya letak di vdata
          delete this.vdata.id;
          sdb
            .collection("app_kasir_pelanggan_minum")
            .doc()
            .set(this.vdata)
            .then((res) => {
              that.getData();
            // that.$nuxt.$emit('getData')

            });
        } else if (this.btn == "update") {
          sdb
            .collection("app_kasir_pelanggan_minum")
            .doc()
            .set(this.vdata)
            .then((res) => {
              that.getData();
            // that.$nuxt.$emit('getData')
            });
        } else if (this.btn == "delete") {
          sdb
            .collection("app_kasir_pelanggan_minum")
            .doc(this.vdata.id)
            .delete()
            .then((res) => {
              that.getData();
            // that.$nuxt.$emit('getData')
            });
        }
        // this.$nuxt.$emit('getData','ok')
      }
    },
  },
  async mounted() {
    this.getData();
    this.getDataPelanggan()
    // this.vdata.tanggal_minum=new Date();
    this.vdata.tanggal_minum=this.format(new Date());
    this.$forceUpdate();
  },
};
</script>